body {
  background-color: #f7f7f7;
  margin: 0; }

.layout {
  padding-top: 70px;

  .grid {
    display: grid !important; } }


